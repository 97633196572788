html {
  position: relative;
  min-height: 100%;
  z-index: -1;
}

body.ReactModal__Body--open {
  overflow-y: hidden;
}

.App > .container {
  margin-top: 15px;
  margin-bottom: 15px;
}
