/* bloc des messages d'alertes  */

@media (max-width: 600px) {
  .card {
    max-width: 160px;
    margin-bottom: 12px;
    background-color: "#dadad9";
  }
}
@media (min-width: 600px) {
  .card {
    max-width: 275px;
    margin-bottom: 12px;
    background-color: "#dadad9";
  }
}
