body {
  margin: 0 0 60px;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
}

.page-footer {
  padding-top: 0;
}

.footer-copyright {
  height: 30px;
  min-height: 30px;
  margin-top: 0px;
  text-align: center;
}

footer.QIbackground {
  background-color: #c4161b;
}

QItextDark {
  color: #414040;
}

QItextLight {
  color: #dadad9;
}

a,
a:link,
a:visited {
  text-decoration: none;
}
/* 
@media (max-width: 600px) {
  body {
    margin: 0 0 82.5px;
  }

  .footer-copyright {
    height: 72.5px;
  }

  .footer-copyright .container {
    height: 72.5px;
    padding-top: 15px;
  }

  .page-footer {
    height: 72.5px;
  } 
}
*/
