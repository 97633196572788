.navbar-light {
  background-color: rgb(36, 189, 31);
}

.img-logo {
  max-width: 36px;
  max-height: 36px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logout {
  background-color: rgb(36, 189, 31);
  color: rgb(0, 0, 0);
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover,
a:active {
  color: rgb(56, 55, 55);
}

.QIbackground {
  background-color: #c4161b;
}

a.QItextDark {
  color: #414040;
}

a.QItextLight {
  color: #dadad9;
}
